import { HebergementsManager, GammesManager, SearchFormManager, StatsManager } from "./components";


class DynamicTariffsApp {
  formManager = new SearchFormManager;
  gammesManager = new GammesManager;
  hebsManager = new HebergementsManager;
  statsManager = new StatsManager;

  constructor() {
    this.form = this.formManager.getFormElement();

    if (!this.form ||
      (!this.hebsManager.hasProducts() && !this.gammesManager.hasProducts())) {
      return;
    }

    // First request after page load
    this.startRequest();

    this.form.addEventListener(
      this.formManager.getCustomSubmitEventType(),
      this.startRequest
    );
  }


  startRequest = async () => {
    this.statsManager.startRecording();
    this.setLoading();

    const searchValues = this.formManager.getCurentValues();
    searchValues.searchAlternatives = false;

    await this.getAvailability(searchValues);

    this.stopLoading();
    this.statsManager.endRecordingAndLogDuration();
  }


  getAvailability = async (searchValues) => {
    const sentData = new URLSearchParams({
      action: "search_availability",
      nonce: pdt_config.nonce,
      travelers: searchValues.travelers.toString(),
      startDate: searchValues.startDate,
      endDate: searchValues.endDate,
    });

    const response = await fetch(pdt_config.ajaxUrl, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: sentData,
    });

    if (!response.ok) {
      console.error("Failed fetching available stays!");
      return;
    }

    const data = await response.json();

    if (!data.success) {
      console.error("Failed getting available stays!");
      return;
    }

    let stays = JSON.parse(data.data)
      ?.availabilityInformationList
      ?.availabilityInformations
      ?.productInformationsList
      ?.productInformations;

    if (!stays) {
      return;
    }

    if (!Array.isArray(stays)) {
      stays = [stays];
    }
    const dates = { start: searchValues.startDate, end: searchValues.endDate };


    this.gammesManager.updateAvailability(stays);
    this.hebsManager.updateAvailability(stays, dates);
  }

  setLoading = () => {
    this.gammesManager.setLoading();
    this.hebsManager.setLoading();
  }

  stopLoading = () => {
    this.gammesManager.stopLoading();
    this.hebsManager.stopLoading();
  }
}


if ('undefined' !== typeof pdt_config) {
  new DynamicTariffsApp;
}
