const months = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre'
];


export const formatDate = date => {
  const dateArr = date.split('-');

  const day = dateArr[2];
  const month = parseInt(dateArr[1]) - 1;

  return `${day} ${months[month]}`;
}


export const formatDateForUrl = dateString => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, '0'),
    month = String(date.getMonth() + 1).padStart(2, '0'),
    year = date.getFullYear()

  return `${day}/${month}/${year}`;
}


export const datesAreEqual = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  return date1.getDate() === date2.getDate()
    && date1.getMonth() === date2.getMonth()
    && date1.getFullYear() === date2.getFullYear();

}
